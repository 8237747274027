import { useTranslation } from "react-i18next";

import { Card, Flex, HorizontalLine, RowItem } from "@vapaus/ui-v2";

import { useCurrencyFormat } from "../../../../../../../packages/i18n/src/hooks/";
import { getMonthlyPaymentLabel } from "../../../../utils/getMonthlyPaymentLabel";
import { BaseOrderViewProps } from "../../type";
import { useOrderSummary } from "../hooks/useOrderSummary";

export function LeasingContract({
  order,
  benefitDefinition,
}: BaseOrderViewProps) {
  const { t } = useTranslation();
  const formatCurrency = useCurrencyFormat(order.currency);
  const {
    monthlyTaxableValue,
    contractTotal,
    totalBikePackage,
    totalMaintenanceBudget,
    downPaymentAmount,
  } = useOrderSummary(order, benefitDefinition);
  const monthlyPaymentLabel = getMonthlyPaymentLabel(t, benefitDefinition);

  return (
    <Card
      title={t("shopV2:orderViewPage.leasingContract.title")}
      subtitle={t("shopV2:orderViewPage.leasingContract.subtitle")}
    >
      <Flex direction="column" gap="xs">
        <RowItem
          label={t("shopV2:orderViewPage.leasingContract.bikePackage")}
          value={formatCurrency(totalBikePackage)}
        />
        <RowItem
          label={t("shopV2:orderViewPage.leasingContract.maintenanceBudget")}
          value={`+${formatCurrency(totalMaintenanceBudget)}`}
        />
        <RowItem
          label={t("shopV2:orderViewPage.leasingContract.downPayment")}
          value={`-${formatCurrency(downPaymentAmount)}`}
        />
        <RowItem
          label={t("shopV2:orderViewPage.leasingContract.contractTotal")}
          value={formatCurrency(contractTotal)}
        />
        <HorizontalLine />
        <RowItem
          label={monthlyPaymentLabel}
          value={formatCurrency(monthlyTaxableValue)}
          valueIsBold
        />
        <RowItem
          label={t("shopV2:orderViewPage.summary.leasingPeriod")}
          value={t("shopV2:orderViewPage.summary.leasingPeriodMonths", {
            value: order.leasingPeriodMonths,
          })}
          valueIsBold
        />
      </Flex>
    </Card>
  );
}
