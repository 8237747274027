import { useMemo, useState } from "react";

import { ColumnDef, Row } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import {
  ShopPaginatedMaintenanceEventOut,
  WorkTypes,
} from "@vapaus/api-codegen";
import { TableEmptyState } from "@vapaus/common-v2";
import {
  Lens3DIcon,
  PaginationProps,
  SortingProps,
  Table,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { DateCell } from "../../../../components/tableCells/DateCell";
import { PriceCell } from "../../../../components/tableCells/PriceCell";
import { Bike } from "../../../../types/Bike";
import { Customer } from "../../../../types/Customer";
import { Price } from "../../../../types/Price";
import { MaintenanceEventModal } from "../MaintenanceEventModal/MaintenanceEventModal";
import { BikeCell } from "./components/cells/BikeCell";
import { CustomerCell } from "./components/cells/CustomerCell";

export type MaintenanceTableDataType = {
  id: string;
  customer?: Customer;
  bike: MaintenanceBike;
  price: MaintenancePrice;
  date: Date;
  workType: WorkTypes;
  description: string;
  contractId: string;
};

export type MaintenancePrice = Price & {
  parts: number;
  partsVatRate: number;
  labour: number;
  labourVatRate: number;
};

export type MaintenanceBike = Pick<Bike, "bikeBrand" | "model"> & {
  frameNumber: string;
};

type MaintenanceEventsTableProps = {
  data?: ShopPaginatedMaintenanceEventOut;
  paginationOptions: PaginationProps;
  sortingOptions: SortingProps;
};

export function MaintenanceEventsTable({
  data,
  paginationOptions,
  sortingOptions,
}: MaintenanceEventsTableProps) {
  const { t } = useTranslation();
  const { isXs, isMobile, isMd, isLg, isXl } = useBreakpoints();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<
    MaintenanceTableDataType | undefined
  >(undefined);

  const getTableRows = (): MaintenanceTableDataType[] => {
    if (!data) {
      return [];
    }

    return data.items.map((item) => ({
      id: item.id,
      customer: item?.user
        ? {
            name: item.user.fullName ?? "",
            email: item.user.email,
            vapausCode: item.vapausCode,
          }
        : undefined,
      bike: {
        bikeBrand: item.bike.bikeBrand,
        model: item.bike.model,
        frameNumber: item.bike.serialNumber,
      },
      price: {
        total: item.totalPrice,
        currency: item.currency,
        parts: item.partsPrice,
        partsVatRate: item.partsVatRate,
        labour: item.workPrice,
        labourVatRate: item.workVatRate,
      },
      date: item.workDate,
      workType: item.workType,
      description: item.workDescription,
      contractId: item.bikeBenefitContractId ?? "",
    }));
  };

  const columns = useMemo<ColumnDef<MaintenanceTableDataType>[]>(
    () => [
      {
        id: "customer",
        header: t("shopV2:maintenance.table.customer"),
        cell: ({ row }) => <CustomerCell customer={row.original.customer} />,
        accessorFn: (row) => row.customer,
        size: isMobile ? 70 : 30,
      },
      ...(!isXs
        ? [
            {
              id: "bike",
              header: t("shopV2:maintenance.table.bike"),
              cell: ({ row }: { row: Row<MaintenanceTableDataType> }) => (
                <BikeCell bike={row.original.bike} />
              ),
              accessorFn: (row: Row<MaintenanceTableDataType>) =>
                row.original.bike,
              enableSorting: true,
              size: 30,
            },
          ]
        : []),
      {
        id: "date",
        header: t("shopV2:maintenance.table.date"),
        cell: ({ row }: { row: Row<MaintenanceTableDataType> }) => (
          <DateCell date={row.original.date} />
        ),
        accessorKey: "date",
        enableSorting: true,
      },
      ...(isMd || isLg || isXl
        ? [
            {
              id: "price",
              header: t("shopV2:maintenance.table.price"),
              cell: ({ row }: { row: Row<MaintenanceTableDataType> }) => (
                <PriceCell price={row.original.price} />
              ),
              accessorKey: "price",
              enableSorting: true,
            },
          ]
        : []),
    ],
    [isLg, isMd, isMobile, isXl, isXs, t],
  );

  const openMaintenanceEventModal = (row: MaintenanceTableDataType) => {
    setSelectedEvent(row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <Table<MaintenanceTableDataType>
        data={getTableRows()}
        columns={columns}
        paginationOptions={paginationOptions}
        sortingOptions={sortingOptions}
        emptyStateComponent={
          <TableEmptyState
            icon={<Lens3DIcon size="lg" />}
            title={t("shopV2:maintenance.table.emptyState.title")}
            subtitle={t("shopV2:maintenance.table.emptyState.subtitle")}
          />
        }
        onRowClick={openMaintenanceEventModal}
      />
      <MaintenanceEventModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        maintenanceEvent={selectedEvent}
      />
    </>
  );
}
