import { useTranslation } from "react-i18next";

import {
  DatePicker,
  Form,
  FormMethods,
  NumberInput,
  TextArea,
} from "@vapaus/form";
import { Currency } from "@vapaus/generated";
import { Flex } from "@vapaus/ui-v2";

import {
  useCurrencyFormat,
  usePercentFormat,
} from "../../../../../../../packages/i18n/src/hooks";
import { MaintenanceEventFormSelect } from "./MaintenanceEventFormSelect";
import { MaintenanceEventFormSchema } from "./useMaintenanceEventFormSchema";

type MaintenanceEventFormProps = {
  currency?: string;
  partsVatRate?: number;
  labourVatRate?: number;
  formMethods: FormMethods<MaintenanceEventFormSchema>;
};

export function MaintenanceEventForm({
  currency,
  partsVatRate,
  labourVatRate,
  formMethods,
}: MaintenanceEventFormProps) {
  const { t } = useTranslation();
  const formatPercent = usePercentFormat();
  const formatCurrency = useCurrencyFormat(
    (currency as Currency) || Currency.EUR,
  );

  return (
    <Form<MaintenanceEventFormSchema> formMethods={formMethods}>
      <Flex direction="column" gap="sm">
        <DatePicker
          name="date"
          label={t("shopV2:maintenance.eventForm.date")}
          valueAsDate
        />
        <MaintenanceEventFormSelect formMethods={formMethods} />
        <NumberInput
          name="partsPrice"
          label={t("shopV2:maintenance.eventForm.parts", {
            value: formatPercent(partsVatRate),
          })}
          placeholder={formatCurrency(0)}
          min="0"
        />
        <NumberInput
          name="labourPrice"
          label={t("shopV2:maintenance.eventForm.labour", {
            value: formatPercent(labourVatRate),
          })}
          placeholder={formatCurrency(0)}
          min="0"
        />
        <TextArea
          name="serviceDescription"
          label={t("shopV2:maintenance.eventForm.serviceDescription.label")}
          placeholder={t(
            "shopV2:maintenance.eventForm.serviceDescription.placeholder",
          )}
        />
      </Flex>
    </Form>
  );
}
