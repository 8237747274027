import { TFunction } from "i18next";

import {
  BenefitDefinitionReadWithOrganisationSummary,
  SupportedOrganisationCountries,
} from "@vapaus/api-codegen";

export function getMonthlyPaymentLabel(
  t: TFunction<"translation">,
  benefitDefinition?: BenefitDefinitionReadWithOrganisationSummary,
) {
  return benefitDefinition?.organisation.country ===
    SupportedOrganisationCountries.Se
    ? t("shopV2:sweden.monthlyRentalFee")
    : t("shopV2:monthlyPayment");
}
