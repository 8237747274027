import { useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { useToaster } from "@vapaus/ui-v2";

import { useSearchBikeContracts } from "../../../../api/bikeContracts";
import { useDeleteMaintenanceEvent } from "../../../../api/maintenanceEvents";
import { MaintenanceTableDataType } from "../MaintenanceEventsTable/MaintenanceEventsTable";

export function useMaintenanceEventModal(
  onClose: () => void,
  maintenanceEvent?: MaintenanceTableDataType,
) {
  const { t } = useTranslation();
  const [isEditEventModalOpen, setIsEditEventModalOpen] =
    useState<boolean>(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);
  const toaster = useToaster();
  const queryClient = useQueryClient();

  const { data: fetchedContract } = useSearchBikeContracts(
    {
      bikeBenefitContractId: maintenanceEvent?.contractId ?? "",
    },
    {
      enabled: !!maintenanceEvent?.contractId,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  const { mutate: deleteMaintenanceEvent, isSuccess } =
    useDeleteMaintenanceEvent({
      maintenanceEventId: maintenanceEvent?.id ?? "",
    });

  useEffect(() => {
    if (isSuccess) {
      onClose();
      setIsDeleteConfirmationModalOpen(false);
      queryClient.invalidateQueries(["maintenanceEvents"]);
      queryClient.resetQueries(["contractsForMaintenance"]);
      toaster.add(
        "success",
        t("shopV2:maintenance.eventModal.deleteModal.confirmationToast"),
      );
    }
  }, [isSuccess]);

  const openDeletionConfirmationModal = () =>
    setIsDeleteConfirmationModalOpen(true);

  const closeDeletionConfirmationModal = () =>
    setIsDeleteConfirmationModalOpen(false);

  const openEditEventModal = () => setIsEditEventModalOpen(true);

  const closeEditEventModal = () => setIsEditEventModalOpen(false);

  return {
    isDeleteConfirmationModalOpen,
    openDeletionConfirmationModal,
    closeDeletionConfirmationModal,
    isEditEventModalOpen,
    openEditEventModal,
    closeEditEventModal,
    deleteMaintenanceEvent,
  };
}
