import { useQuery } from "@tanstack/react-query";

import {
  ProductVatRatesApi,
  SupportedOrganisationCountries,
} from "@vapaus/api-codegen";
import { apiConfiguration } from "@vapaus/auth-v2";

export const productVatRatesApi = new ProductVatRatesApi(apiConfiguration);

export const useGetProductsVatRates = (
  country?: SupportedOrganisationCountries,
  atTime?: Date,
) => {
  return useQuery({
    queryKey: ["productsVatRates", country, atTime],
    queryFn: () =>
      productVatRatesApi.productVatRatesGetProductVatRates({
        country: country ?? SupportedOrganisationCountries.Fi,
        atTime,
      }),
    enabled: !!country,
  });
};
