/* tslint:disable */

/* eslint-disable */

/**
 * Vapaus
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import type { AdditionalServiceType } from "./AdditionalServiceType";
import {
  AdditionalServiceTypeFromJSON,
  AdditionalServiceTypeFromJSONTyped,
  AdditionalServiceTypeToJSON,
} from "./AdditionalServiceType";

/**
 *
 * @export
 * @interface BikeBenefitOrderAdditionalServiceWrite
 */
export interface BikeBenefitOrderAdditionalServiceWrite {
  /**
   * Leave empty in case of creating an Additional Service
   * @type {string}
   * @memberof BikeBenefitOrderAdditionalServiceWrite
   */
  id?: string;
  /**
   *
   * @type {AdditionalServiceType}
   * @memberof BikeBenefitOrderAdditionalServiceWrite
   */
  serviceType: AdditionalServiceType;
  /**
   *
   * @type {string}
   * @memberof BikeBenefitOrderAdditionalServiceWrite
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderAdditionalServiceWrite
   */
  priceGross: number;
  /**
   *
   * @type {number}
   * @memberof BikeBenefitOrderAdditionalServiceWrite
   */
  vatRate: number;
}

/**
 * Check if a given object implements the BikeBenefitOrderAdditionalServiceWrite interface.
 */
export function instanceOfBikeBenefitOrderAdditionalServiceWrite(
  value: object,
): boolean {
  let isInstance = true;
  isInstance = isInstance && "serviceType" in value;
  isInstance = isInstance && "priceGross" in value;
  isInstance = isInstance && "vatRate" in value;

  return isInstance;
}

export function BikeBenefitOrderAdditionalServiceWriteFromJSON(
  json: any,
): BikeBenefitOrderAdditionalServiceWrite {
  return BikeBenefitOrderAdditionalServiceWriteFromJSONTyped(json, false);
}

export function BikeBenefitOrderAdditionalServiceWriteFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): BikeBenefitOrderAdditionalServiceWrite {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    serviceType: AdditionalServiceTypeFromJSON(json["service_type"]),
    description: !exists(json, "description") ? undefined : json["description"],
    priceGross: json["price_gross"],
    vatRate: json["vat_rate"],
  };
}

export function BikeBenefitOrderAdditionalServiceWriteToJSON(
  value?: BikeBenefitOrderAdditionalServiceWrite | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    service_type: AdditionalServiceTypeToJSON(value.serviceType),
    description: value.description,
    price_gross: value.priceGross,
    vat_rate: value.vatRate,
  };
}
