import { round, sum, sumBy } from "lodash-es";

import { toGross, toNet } from "@vapaus/utils";

interface Order {
  flatVatRate?: number;
  netBikePrice: number;
  accessories: Array<Accessory>;
  additionalServices: Array<AdditionalService>;
  deliveryTransport?: DeliveryTransport;
}

interface Accessory {
  purchasePrice: number;
  purchaseVatRate: number;
}

interface AdditionalService {
  priceGross: number;
  vatRate: number;
}

interface DeliveryTransport {
  priceGross: number;
  vatRate: number;
}

export const getOrderTotalPrice = (
  order: Order,
  bikeDiscount = 0,
  accessoryDiscount = 0,
): number => {
  const toOrderVatRate = (price: number, vatRate: number) =>
    toGross(toNet(price || 0, vatRate), order.flatVatRate ?? 0);

  const bikePrice = toOrderVatRate(order.netBikePrice, 0);

  const bikeTotal = bikePrice - bikePrice * bikeDiscount;

  const accessoriesTotal = sumBy(order.accessories, (accessory: Accessory) => {
    const price = toOrderVatRate(
      accessory.purchasePrice,
      accessory.purchaseVatRate,
    );
    return price - price * accessoryDiscount;
  });

  const additionalServiceTotal = sumBy(
    order.additionalServices?.map((service) =>
      toOrderVatRate(service.priceGross, service.vatRate),
    ),
  );
  const transportTotal = order.deliveryTransport
    ? toOrderVatRate(
        order.deliveryTransport.priceGross,
        order.deliveryTransport.vatRate,
      )
    : 0;

  const total = sum([
    bikeTotal,
    accessoriesTotal,
    additionalServiceTotal,
    transportTotal,
  ]);
  return parseFloat(total.toFixed(2));
};
