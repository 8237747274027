import { useTranslation } from "react-i18next";

import { SupportedOrganisationCountries } from "@vapaus/api-codegen";
import { Modal } from "@vapaus/ui-v2";

import { MaintenanceEventForm } from "../MaintenanceEventForm/MaintenanceEventForm";
import { useAddMaintenanceEventForm } from "./useAddMaintenanceEventForm";

type MaintenanceEventFormModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly contractId: string;
  readonly country: SupportedOrganisationCountries;
  readonly currency: string;
  readonly handleBudgetChange: (budgetDifference: number) => void;
};

export function AddMaintenanceEventModal({
  isOpen,
  onClose,
  contractId,
  country,
  currency,
  handleBudgetChange,
}: MaintenanceEventFormModalProps) {
  const { t } = useTranslation();

  const { formMethods, mutation, partsVatRate, labourVatRate } =
    useAddMaintenanceEventForm(
      contractId,
      country,
      onClose,
      handleBudgetChange,
    );

  const handleCancel = () => {
    onClose();
    formMethods.reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t("shopV2:maintenance.wizard.bikeView.newEventModal.title")}
      description={t(
        "shopV2:maintenance.wizard.bikeView.newEventModal.description",
      )}
      cancelText={t("commonV2:cancel")}
      confirmText={t("commonV2:submit")}
      onCancel={handleCancel}
      onClose={onClose}
      onConfirm={formMethods._handleSubmit}
      isSubmitting={mutation.isLoading}
    >
      <MaintenanceEventForm
        currency={currency}
        partsVatRate={partsVatRate}
        labourVatRate={labourVatRate}
        formMethods={formMethods}
      />
    </Modal>
  );
}
