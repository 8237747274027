import { useTranslation } from "react-i18next";

import { Flex, Modal, Typography } from "@vapaus/ui-v2";

import { MaintenanceEventForm } from "../../MaintenanceEventForm/MaintenanceEventForm";
import { MaintenanceTableDataType } from "../../MaintenanceEventsTable/MaintenanceEventsTable";
import { useEditMaintenanceEventForm } from "./useEditMaintenanceEventForm";

type EditMaintenanceEventModalProps = {
  maintenanceEvent?: MaintenanceTableDataType;
  isOpen: boolean;
  onClose: () => void;
  onParentClose: () => void;
};

export function EditMaintenanceEventModal({
  maintenanceEvent,
  isOpen,
  onClose,
  onParentClose,
}: EditMaintenanceEventModalProps) {
  const { t } = useTranslation();

  const formDefaultValues = {
    date: new Date(maintenanceEvent?.date ?? ""),
    type: maintenanceEvent?.workType ?? "",
    partsPrice: maintenanceEvent?.price.parts ?? 0,
    labourPrice: maintenanceEvent?.price.labour ?? 0,
    serviceDescription: maintenanceEvent?.description ?? "",
  };

  const { formMethods, mutation } = useEditMaintenanceEventForm(
    () => {
      onClose();
      onParentClose();
    },
    maintenanceEvent?.id,
    formDefaultValues,
  );

  const handleCancel = () => {
    onClose();
    formMethods.reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      cancelText={t("commonV2:cancel")}
      confirmText={t("commonV2:save")}
      onCancel={handleCancel}
      onConfirm={formMethods._handleSubmit}
      disableConfirm={!formMethods.formState.isDirty}
      isSubmitting={mutation.isLoading}
    >
      <Flex direction="column" gap="md">
        <Flex direction="column" align="center" gap="xxs">
          <Typography variant="h6">
            {maintenanceEvent?.bike.bikeBrand.name}{" "}
            {maintenanceEvent?.bike.model}
          </Typography>
          <Typography variant="h2">{maintenanceEvent?.workType}</Typography>
          <Typography>
            {maintenanceEvent?.bike.frameNumber}
            {maintenanceEvent?.customer ? " · " : null}
            {maintenanceEvent?.customer?.vapausCode}
          </Typography>
        </Flex>
        <MaintenanceEventForm
          currency={maintenanceEvent?.price.currency}
          partsVatRate={maintenanceEvent?.price.partsVatRate}
          labourVatRate={maintenanceEvent?.price.labourVatRate}
          formMethods={formMethods}
        />
      </Flex>
    </Modal>
  );
}
