import React, { ReactNode, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

import { Announcements, ProfileDropdown } from "@vapaus/common-v2";
import { useCurrentUser } from "@vapaus/shared-api";
import {
  AccountSwitcher,
  LogoutIcon,
  MobileNavigationBar,
  NavigationBar,
  PersonIcon,
  SettingsIcon,
  useBreakpoints,
} from "@vapaus/ui-v2";

import { Path } from "../../constants/paths";
import { useShopContext } from "../../contexts/ShopContext";
import { AccountSwitcherMenu } from "./components/AccountSwitcherMenu";
import { MobileNavigationButtons } from "./components/MobileNavigationButtons";
import { NavigationButtons } from "./components/NavigationButtons";

export function Navigation(): ReactNode {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useBreakpoints();
  const { selectedShop, managedShops } = useShopContext();
  const { data: user } = useCurrentUser();
  const [profileMenuTrigger, setProfileMenuTrigger] =
    useState<null | HTMLElement>(null);
  const [accountSwitcherMenuTrigger, setAccountSwitcherMenuTrigger] =
    useState<null | HTMLElement>(null);

  const handleProfileButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAccountSwitcherMenuTrigger(null);
    setProfileMenuTrigger(profileMenuTrigger ? null : event.currentTarget);
  };

  const handleAccountButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setProfileMenuTrigger(null);
    setAccountSwitcherMenuTrigger(
      accountSwitcherMenuTrigger ? null : event.currentTarget,
    );
  };

  const closeProfileMenu = () => {
    setProfileMenuTrigger(null);
  };

  const closeAccountSwitcherMenu = () => {
    setAccountSwitcherMenuTrigger(null);
  };

  const menuProps = useMemo(
    () => ({
      header: {
        icon: PersonIcon,
        title: user?.fullName ?? "",
        description: user?.email ?? "",
      },
      items: [
        {
          label: t("commonV2:settings"),
          value: Path.settingsUserProfile,
          icon: SettingsIcon,
        },
        {
          label: t("commonV2:logout"),
          value: Path.logout,
          icon: LogoutIcon,
        },
      ],
    }),
    [t, user?.email, user?.fullName],
  );

  const AccountSwitcherComponent = (
    <>
      <AccountSwitcher
        title={t("shopV2:navigation:accountSwitcher:title")}
        selectedAccount={selectedShop?.name ?? ""}
        onClick={handleAccountButtonClick}
      />
      <AccountSwitcherMenu
        accountSwitcherMenuTrigger={accountSwitcherMenuTrigger}
        closeAccountSwitcherMenu={closeAccountSwitcherMenu}
      />
    </>
  );

  return (
    <>
      {user && user.country !== undefined && (
        <Announcements
          country={user.country}
          scope="shop"
          language={user.language || "EN"}
        />
      )}
      <NavigationBar
        onLogoClick={() => navigate("")}
        mainContent={<NavigationButtons />}
        secondaryContent={
          managedShops.length > 1 ? AccountSwitcherComponent : null
        }
        profileMenu={
          <ProfileDropdown
            profileMenuTrigger={profileMenuTrigger}
            handleProfileMenuClick={handleProfileButtonClick}
            closeProfileMenu={closeProfileMenu}
            {...menuProps}
          />
        }
      />
      <Outlet />
      {isMobile ? (
        <MobileNavigationBar>
          <MobileNavigationButtons />
        </MobileNavigationBar>
      ) : null}
    </>
  );
}
